import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TimestampComponent} from "./timestamp.component";
import {
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule
} from "@angular/material";
import {RouterModule} from "@angular/router";
import {TimestampSummaryModule} from "../../timestamp/timestamp-summary/timestamp-summary.module";
import {HolidayComponent} from "./holiday/holiday.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatFormFieldModule, MatInputModule, MatButtonModule, MatSelectModule,
        MatIconModule,
        MatTooltipModule,
        TimestampSummaryModule
    ],
    declarations: [
        TimestampComponent,
        HolidayComponent
    ],
    exports: [
        TimestampComponent
    ]
})
export class TimestampModule { }
