import {Component, OnInit, ViewChild} from '@angular/core';
import {ToolHelperModule} from "../../../modules/tool-helper.module";
import {BranchService} from "../../../services/branch.service";
import {TimestampService} from "../../../services/timestamp.service";
import {FormControl, FormGroup, NgForm, Validators} from "@angular/forms";
import {Users} from "../../../schemas/users";
import {Branch} from "../../../schemas/branch";
import {UsersService} from "../../../services/users.service";
import {Schedule} from "../../../schemas/schedule";
import {FormHelperModule} from "../../../modules/form-helper.module";
import {MatSnackBar} from "@angular/material";
import {formatNumber} from "@angular/common";
import {BuildingType} from "../../../schemas/building-type";
import {TimestampSummaryComponent} from "../../timestamp/timestamp-summary/timestamp-summary.component";
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-timestamp',
  templateUrl: './timestamp.component.html',
  styleUrls: ['./timestamp.component.scss']
})
export class TimestampComponent implements OnInit {

    @ViewChild('branchSelect') branchSelect;
    @ViewChild(TimestampSummaryComponent) timestampSummaryComponent: TimestampSummaryComponent;

    public slipUrl = environment.slipUrl;

    public currentDate: string = null;
    public isTransactionLoaded: boolean = true;

    public isSupportGPS: boolean = false;
    public isGPSLoaded: boolean = true;
    public timestampContentStatus: string = '<i class="material-icons">location_city</i> กำลังตรวจสอบตำแหน่งพิกัด...';

    public isBranchLoaded: boolean = true;
    public dataBranch: Branch | BuildingType | any = new Branch();
    public isNearBranchLoaded: boolean = true;
    public dataNearBranch: Branch[] = [];

    public isUsersLoaded: boolean = true;
    public dataUsers: Users | Schedule | any = new Users();

    public form = new FormGroup({
        branch_id: new FormControl('', [Validators.required]),
        users_code: new FormControl('', [Validators.required]),
    });

    constructor() { }

    ngOnInit() {
        this.clock();
        this.checkGPS();
    }

    public onSubmit ($event) {
        let formHelper = new FormHelperModule();

        //modify

        if  ( !this.isGPSLoaded )
            return formHelper.notify('danger', "โปรดรอสักครู่ ระบบกำลังดึงข้อมูลตำแหน่งปัจจุบันของคุณ");
        if ( !this.isSupportGPS )
            return formHelper.notify('danger', this.timestampContentStatus = "Geolocation is not supported by this browser.");
        if ( !this.isNearBranchLoaded )
            return formHelper.notify('danger', "โปรดรอสักครู่ ระบบกำลังดึงข้อมูลสาขาที่อยู่ในระยะตำแหน่งปัจจุบันของคุณ");

        //get data users
        this.timestampContentStatus = '<i class="material-icons">perm_contact_calendar</i> กำลังดึงข้อมูลผู้ใช้งาน...';
        this.isUsersLoaded = false;
        const usersCode = this.form.get('users_code').value.replace(/\s+/g, '').toLowerCase().replace("o", "0");
        UsersService.getUsersById({users_code: usersCode}).subscribe(
            (data: any) => {

                if ( !data.users ) {
                    formHelper.notify('danger', 'ไม่พบข้อมูลผู้ใช้งานในระบบ');
                    this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                    return;
                }

                if ( data.users.utype_id == 3 ) {
                    formHelper.notify('danger', 'หน้าต่างลงเวลานี้ไม่อนุญาตให้ ' + data.users.utype_name + ' ทำรายการได้');
                    this.timestampContentStatus = '<i class="material-icons">highlight_off</i> ' + 'หน้าต่างลงเวลานี้ไม่อนุญาตให้ ' + data.users.utype_name + ' ทำรายการได้';
                    return;
                }

                this.dataUsers = data.users;

                //get data branch
                this.timestampContentStatus = '<i class="material-icons">location_city</i> กำลังดึงข้อมูลสาขา...';
                this.isBranchLoaded = false;
                BranchService.getBranchById({branch_id: this.form.get('branch_id').value}).subscribe(
                    (data: any) => {

                        if ( !data.branch ) {
                            formHelper.notify('danger', 'ไม่พบข้อมูลสาขา');
                            this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                            return;
                        }

                        this.dataBranch = data.branch;

                        navigator.geolocation.getCurrentPosition(position => {

                            //get data position GPS
                            let distance: number = 0;
                            this.timestampContentStatus = '<i class="material-icons">room</i> กำลังดึงข้อมูลตำแหน่ง...';
                            if ((distance = this.calculateDistance(this.dataBranch.branch_lat, this.dataBranch.branch_long, position.coords.latitude, position.coords.longitude)) > 1000)
                                this.timestampContentStatus = '<i class="material-icons">report</i> ระยะปัจจุบันของคุณห่างจากตำแหน่งบริษัท ' + formatNumber(distance, "en-US","1.1-2") + ' เมตร คุณไม่อยู่ในระยะ 300 เมตรจากตำแหน่งของบริษัท';
                            else {

                                let schedule = {
                                    btype_before_clockin: null,
                                    btype_clockin: null,
                                    btype_after_clockin: null,
                                    btype_clockout: null,
                                };

                                //maid
                                if ( this.dataUsers.utype_id == 1 ) {
                                    schedule.btype_before_clockin = this.dataBranch.btype_maid_before_clockin;
                                    schedule.btype_clockin = this.dataBranch.btype_maid_clockin;
                                    schedule.btype_after_clockin = this.dataBranch.btype_maid_after_clockin;
                                    schedule.btype_clockout = this.dataBranch.btype_maid_clockout;
                                } else if ( this.dataUsers.utype_id == 2 ) { // traffic
                                    schedule.btype_before_clockin = this.dataBranch.btype_traffic_before_clockin;
                                    schedule.btype_clockin = this.dataBranch.btype_traffic_clockin;
                                    schedule.btype_after_clockin = this.dataBranch.btype_traffic_after_clockin;
                                    schedule.btype_clockout = this.dataBranch.btype_traffic_clockout;
                                }

                                let dataPost = {
                                    latitude: position.coords.latitude,
                                    longitude: position.coords.longitude,
                                    users_id: this.dataUsers.users_id,
                                    users_branch_id: this.dataUsers.branch_id,
                                    utype_id: this.dataUsers.utype_id,
                                    branch_id: this.dataBranch.branch_id,
                                    branch_code: ((this.dataUsers.utype_id == 4 || this.dataUsers.utype_id == 6) ? this.dataBranch.branch_code : (this.dataUsers.branch_id == this.dataBranch.branch_id ? this.dataUsers.users_coderef : this.dataBranch.branch_code)),
                                    btype_id: this.dataBranch.btype_id,
                                    btype_schedule: this.dataBranch.btype_schedule,
                                    users_code: this.dataUsers.users_code,
                                    btype_before_clockin: schedule.btype_before_clockin,
                                    btype_clockin: schedule.btype_clockin,
                                    btype_after_clockin: schedule.btype_after_clockin,
                                    btype_clockout: schedule.btype_clockout,
                                };
                                this.isTransactionLoaded = false;
                                this.timestampContentStatus = '<i class="material-icons">swap_horiz</i> กำลังทำรายการ...';
                                TimestampService.insertTimestamp(dataPost).subscribe(
                                    (data: any) => {

                                        if ( data.error ) {
                                            formHelper.notify('danger', data.error);
                                            this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                                            return;
                                        }

                                        //get summary timestamp
                                        this.timestampSummaryComponent.summary.timestampResponse = data.timestamp;
                                        this.timestampSummaryComponent.summary.timestampStatus = data.timestamp_status;
                                        this.timestampSummaryComponent.summary.timestampType = data.timestamp_type;
                                        this.timestampSummaryComponent.summary.timestampCheckinType = data.timestamp_checkin_type;
                                        this.timestampSummaryComponent.summary.timestampRound = data.timestamp_round;
                                        this.timestampSummaryComponent.summary.dataUsers = this.dataUsers;
                                        this.timestampSummaryComponent.summary.dataBranch = this.dataBranch;

                                        //evt
                                        this.form.reset();
                                        formHelper.notify('success', 'ลงเวลาเข้าระบบสำเร็จ');
                                        this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                                        $('#summaryTimestampModal').modal('show');


                                    }, () => { this.isTransactionLoaded = true; this.timestampContentStatus = '<i class="material-icons">highlight_off</i> พบปัญหาระหว่างการทำรายการ'; },
                                    () => { this.isTransactionLoaded = true; }
                                );

                            }
                        });

                    }, () => { this.isBranchLoaded = true; this.timestampContentStatus = '<i class="material-icons">highlight_off</i> พบปัญหาการดึงข้อมูลสาขา'; },
                    () => { this.isBranchLoaded = true; }
                );
            }, () => { this.isUsersLoaded = true; this.timestampContentStatus = '<i class="material-icons">highlight_off</i> พบปัญหาการดึงข้อมูลผู้ใช้งาน'; },
            () => { this.isUsersLoaded = true; }
        );
    }
    
    public triggerDataNearBranch(position: any) {
        
        this.isNearBranchLoaded = false;
        this.timestampContentStatus = '<i class="material-icons">location_city</i> ระบบกำลังดึงข้อมูลสาขาที่อยู่ในระยะตำแหน่งปัจจุบันของคุณ...';
        BranchService.getNearBranch({latitude: position.coords.latitude, longitude: position.coords.longitude}).subscribe(
            (data: any) => {

                this.dataNearBranch = data.branch;
                if ( this.dataNearBranch )
                    this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                else
                    this.timestampContentStatus = '<i class="material-icons">room</i> ระบบไม่พบสาขาที่อยู่ใกล้พิกัดของคุณ...';

                //toggle select
                this.branchSelect.open();

            }, () => { this.isNearBranchLoaded = true; this.timestampContentStatus = '<i class="material-icons">highlight_off</i> พบปัญหาการดึงข้อมูลสาขา'; },
            () => { this.isNearBranchLoaded = true; }
        )
    }

    public checkGPS () {
        let formHelper: FormHelperModule = new FormHelperModule();

        this.isGPSLoaded = false;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {

                this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                console.log('Geolocation is supported by this browser.');
                this.isSupportGPS = true;
                this.isGPSLoaded = true;
                this.triggerDataNearBranch(position);
            });
        } else {
            this.timestampContentStatus = '<i class="material-icons">location_off</i> กรุณาตั้งค่าอุปกรณ์ให้ยอมรับการเข้าถึง GPS ของคุณ';
            formHelper.notify('danger', "Geolocation is not supported by this browser.");
            this.isSupportGPS = false;
            this.isGPSLoaded = true;
        }
    }

    public calculateDistance (lat1,lng1,lat2,lng2) {
        //format
        lat1 = parseFloat(lat1);
        lng1 = parseFloat(lng1);
        lat2 = parseFloat(lat2);
        lng2 = parseFloat(lng2);
        let R = 6371; // Radius of the earth in km
        let dLat = this.deg2rad(lat2-lat1);  // deg2rad below
        let dLon = this.deg2rad(lng2-lng1);
        let a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        let d = R * c; // Distance in km
        return d * 1000;//meter
    };

    public deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    public clock() {
        this.currentDate = ToolHelperModule.getCurrentDate(null);
        setTimeout(callback => {
            this.clock();
        }, 500);
    }

    public compareObjects(o1: any, o2: any): boolean {
        return o1 && o2 && o1 == o2;
    }
}
