export class Users {
    users_id: number;
    users_code: string;
    users_coderef: string;
    users_password: string;
    utype_id: number;
    branch_id: number;
    department_id: number;
    users_title: string;
    users_name: string;
    users_surname: string;
    users_timestamp_auto: number;
    users_update: Date;
    users_create: Date;
    users_create_id: number;
}