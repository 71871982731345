import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatIconModule,
} from "@angular/material";
import {TimestampSummaryComponent} from "./timestamp-summary.component";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
    ],
    declarations: [
        TimestampSummaryComponent,
    ],
    exports: [
        TimestampSummaryComponent
    ]
})
export class TimestampSummaryModule { }
