import { Injectable } from '@angular/core';
import {HttpClient, HttpXhrBackend, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TimestampService {

    //attr
    public static http = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    public static httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    
    constructor() { }

    public static getSchedule(data : object) {
        return this.http.post(environment.getBaseUrl('timestamp_service/get_schedule'), data, this.httpOptions);
    }

    public static insertTimestamp(data : object) {
        return this.http.post(environment.getBaseUrl('timestamp_service/insert_timestamp'), data, this.httpOptions);
    }

    public static getTimestampDaily(data : object) {
        return this.http.post(environment.getBaseUrl('timestamp_service/get_timestamp_daily'), data, this.httpOptions);
    }
}
