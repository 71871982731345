import { Injectable } from '@angular/core';
import {HttpClient, HttpXhrBackend, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BranchService {

    //attr
    public static http = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    public static httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    
    constructor() { }

    //branch
    public static getBranch(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/get_branch'), data);
    }

    public static getNearBranch(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/get_near_branch'), data);
    }

    public static getBuildingType(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/get_building_type'), data);
    }

    public static getSector(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/get_sector'), data);
    }

    public static getProvince(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/get_province'), data);
    }

    public static getDistrict(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/get_district'), data);
    }

    public static getBranchById(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/get_branch_by_id'), data);
    }

    public static insertBranch(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/insert_branch'), data, this.httpOptions);
    }

    public static editBranch(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/edit_branch'), data, this.httpOptions);
    }

    public static deleteBranch(data : object) {
        return this.http.post(environment.getBaseUrl('branch_service/delete_branch'), data, this.httpOptions);
    }

    public static getDepartment(data: object) {
        return this.http.post(environment.getBaseUrl('branch_service/get_department'), data);
    }

    public static getDepartmentById(data: object) {
        return this.http.post(environment.getBaseUrl('branch_service/get_department_by_id'), data);
    }

}
