
<!-- The Modal -->
<div class="modal" id="holidayModal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"><i class="material-icons">today</i>&nbsp;วันหยุดประเพณี 2562</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <td>วันที่</td>
                                <td>วันหยุด</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1 มกราคม</td>
                                <td>วันขึ้นปีใหม่</td>
                            </tr>
                            <tr>
                                <td>19 กุมพาพันธ์</td>
                                <td>วันมาฆบูชา</td>
                            </tr>
                            <tr>
                                <td>6 เมษายน</td>
                                <td>วันพระบาทสมเด็จพระพุทธยอดฟ้าจุฬาโลกมหาราช</td>
                            </tr>
                            <tr>
                                <td>13 เมษายน</td>
                                <td>วันสงกรานต์</td>
                            </tr>
                            <tr>
                                <td>14 เมษายน</td>
                                <td>วันสงกรานต์</td>
                            </tr>
                            <tr>
                                <td>15 เมษายน</td>
                                <td>วันสงกรานต์</td>
                            </tr>
                            <tr>
                                <td>1 พฤษภาคม</td>
                                <td>วันแรงงานแห่งชาตื</td>
                            </tr>
                            <tr>
                                <td>18 พฤษภาคม</td>
                                <td>วันวิสาขบูชา</td>
                            </tr>
                            <tr>
                                <td>28 กรกฎาคม</td>
                                <td>วันเฉลิมพระชนมพรรษาสมเด็จพระเจ้าอยู่หัวมหาวชิราลงกรณบดินทรเทพยวรางกูร</td>
                            </tr>
                            <tr>
                                <td>12 สิงหาคม</td>
                                <td>วันเฉลิมพระชนมพรรษา สมเด็จพระนางเจ้าฯ พระบรมราชินีนาถ ในพระบาทสมเด็จพระปรมินทรมหาภูมิพลอดุลยเดช บรมนาถบพิตร</td>
                            </tr>
                            <tr>
                                <td>13 ตุลาคม</td>
                                <td>วันคล้ายวันสวรรคตพระบาทสมเด็จพระปรมินทร</td>
                            </tr>
                            <tr>
                                <td>5 ธันวาคม</td>
                                <td>วันพ่อแห่งชาติ</td>
                            </tr>
                            <tr>
                                <td>31 ธันวาคม</td>
                                <td>วันสิ้นปี</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">ปิด</button>
            </div>

        </div>
    </div>
</div>