import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

declare var $: any;

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})

export class FormHelperModule {

    //attr
    formEventData : string = null;

    public setFormEventLoading(form : object) {
        this.formEventData = $(form).find('button[type=submit]').html();
        $(form).find('button[type=submit]').html('<i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Connecting...').prop('disabled', true);
    }

    public resetFormEventLoading(form : object) {
        $(form).find('button[type=submit]').html(this.formEventData).prop('disabled', false);
    }

    public compareObjects(o1: any, o2: any): boolean {
        return o1 && o2 && o1 == o2;
    }

    public notify(panel: string = "success", message: string) {

        $.notify({
            icon: "notifications",
            message: message
        },{
            type: panel,
            timer: 100,
            z_index: 99999,
            placement: {
                from: 'bottom',
                align: 'center'
            },
            template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>'
        });
    }
}
