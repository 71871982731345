import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as $ from 'jquery';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class ToolHelperModule {

    //attr

    constructor () { }

    public static getCurrentDate(format: string = 'Y-m-d H:i:s', lang: string = 'en'): string {
        let currentDate = new Date();
        if ( 'Y-m-d' == format )
            return (currentDate.getFullYear() + (lang == 'th' ? 543 : 0)) + '-' +
                ((currentDate.getMonth() + 1) < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1) ) + '-' +
                (currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate());
        else
            return (currentDate.getFullYear() + (lang == 'th' ? 543 : 0)) + '-' +
                ((currentDate.getMonth() + 1) < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1) ) + '-' +
                (currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()) + ' ' +
                (currentDate.getHours() < 10 ? '0' + currentDate.getHours() : currentDate.getHours()) + ':' +
                (currentDate.getMinutes() < 10 ? '0' + currentDate.getMinutes() : currentDate.getMinutes()) + ':' +
                (currentDate.getSeconds() < 10 ? '0' + currentDate.getSeconds() : currentDate.getSeconds());
    }

    public static formatDateISO (dateString: string) {

        if ( !dateString )
            return null;

        let date = new Date(dateString);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        let dt = date.getDate().toString();

        if (parseInt(dt) < 10)
            dt = '0' + dt;

        if (parseInt(month) < 10)
            month = '0' + month;

        return year + '-' + month + '-' + dt;
    }

    public static deleteNullObject (object) {
        for (var key in object) {
            if ( object[key] == null )
                delete object[key];
        }
    }

}
