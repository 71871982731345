<div class="container-fluid">

    <div class="row" style="margin-top: 15px">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-success">
                    <h4 class="card-title ">หน้าลงเวลาเข้างานพนักงานขับรถ (ลงเวลา,ลงเวลาแทน)</h4>
                    <p class="card-category">{{currentDate}}</p>
                </div>
                <div class="card-body">
                    <form (ngSubmit)="ngForm.form.valid && onSubmit($event)" #ngForm="ngForm">
                        <div class="row align-items-center">
                            <div class="col">

                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4" style="border: 1px dashed gainsboro; text-align: center">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <mat-form-field class="full-width">
                                                <label style="font-size: 16px">กรอกรหัสพนักงาน <span class="text-danger">*</span></label>
                                                <input matInput type="text" name="users_code" [(ngModel)]="form.users_code" required>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <p [innerHTML]="timestampContentStatus"></p>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <button type="submit" mat-raised-button class="btn btn-primary btn-lg btn-block" [disabled]="!isGPSLoaded || !isDepartmentLoaded || !isTransactionLoaded || !isUsersLoaded" *ngIf="isGPSLoaded || isDepartmentLoaded || isTransactionLoaded || isUsersLoaded"><span *ngIf="isGPSLoaded && isDepartmentLoaded && isTransactionLoaded && isUsersLoaded">ลงเวลา</span><span *ngIf="!isGPSLoaded || !isDepartmentLoaded || !isTransactionLoaded || !isUsersLoaded"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Connecting...</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">

                            </div>
                        </div>
                    </form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group text-left">
                                <br>
                                <a [href]="slipUrl">สลิปเงินเดือน</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-timestamp-summary></app-timestamp-summary>
