import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-timestamp-summary',
    templateUrl: './timestamp-summary.component.html',
    styleUrls: ['./timestamp-summary.component.scss']
})
export class TimestampSummaryComponent implements OnInit {

    public summary: any = {dataUsers: {}, dataBranch: {}};

    constructor() { }

    ngOnInit() {
    }

}
