import { Injectable } from '@angular/core';
import {Users} from "../schemas/users";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor() { }

    //get routing path
    public static getMyPath (userType : string = null): string {
        //get type if don't exist
        if ( !userType )
            userType = AuthService.getSession('utype_id');
        
        switch (userType) {
            
            case "1" : return 'employee/dashboard';
            case "2" : return 'employee/dashboard';
            case "3" : return 'employee/dashboard';
            case "4" : return 'employee/dashboard';
            case "5" : return 'admin/dashboard';

            default:
                this.sessionDestroy();
                return "login";
        }
    }
    
    public static getMyRootPath(userType : string = null): string {
        //get type if don't exist
        if ( !userType )
            userType = AuthService.getSession('utype_id');

        switch (userType) {

            case "1" : return 'employee';
            case "5" : return 'admin';

            default:
                this.sessionDestroy();
                return "login";
        }
    }

    //session
    public static sessionCreate (users: Users) : void {
        // users
        localStorage.setItem("users_id", users.users_id.toString());
        localStorage.setItem("utype_id", users.utype_id.toString());
        localStorage.setItem("branch_id", users.branch_id ? users.branch_id.toString() : null);
        localStorage.setItem("users_title", users.users_title);
        localStorage.setItem("users_name", users.users_name);
        localStorage.setItem("users_surname", users.users_surname);
        localStorage.setItem("loggedIn", 'true');
    }

    /*public static sessionSetPermission (usersPermission : UsersPermission) {
        // users_permission
        localStorage.setItem('users_permission', JSON.stringify(usersPermission));
    }*/

    public static sessionDestroy () : void {
        localStorage.clear();
    }

    public static getSession (key: string) : string {
        return localStorage.getItem(key);
    }
}
