export class Branch {
    branch_id: number;
    branch_code: string;
    branch_name: string;
    branch_lat: string;
    branch_long: string;
    branch_tel: string;
    branch_address: string;
    branch_rate_maid: number;
    branch_rate_traffic: number;
    branch_rate_driver: number;
    branch_update: Date;
    branch_create: Date;
    branch_create_id: number;
}