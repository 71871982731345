import { Component, OnInit, ViewChild } from '@angular/core';
import {Users} from "../../../schemas/users";
import {FormHelperModule} from "../../../modules/form-helper.module";
import {UsersService} from "../../../services/users.service";
import {TimestampService} from "../../../services/timestamp.service";
import {FormControl, NgForm, Validators} from "@angular/forms";
import {BuildingType} from "../../../schemas/building-type";
import {Branch} from "../../../schemas/branch";
import {Schedule} from "../../../schemas/schedule";
import {BranchService} from "../../../services/branch.service";
import {ToolHelperModule} from "../../../modules/tool-helper.module";
import {MatSnackBar} from "@angular/material";
import {Department} from "../../../schemas/department";
import {TimestampSummaryComponent} from "../../timestamp/timestamp-summary/timestamp-summary.component";
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-timestamp-driver',
  templateUrl: './timestamp-driver.component.html',
  styleUrls: ['./timestamp-driver.component.scss']
})
export class TimestampDriverComponent implements OnInit {


    @ViewChild(NgForm) ngForm: NgForm;
    @ViewChild(TimestampSummaryComponent) timestampSummaryComponent: TimestampSummaryComponent;

    public slipUrl = environment.slipUrl;

    public currentDate: string = null;
    public form: Users | any = new Users();
    public isTransactionLoaded: boolean = true;
    
    public isGPSLoaded: boolean = true;
    public timestampContentStatus: string = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';


    public isDepartmentLoaded: boolean = true;
    public dataDepartment: Branch | Department | BuildingType | any = new Department();

    public isUsersLoaded: boolean = true;
    public dataUsers: Users | Schedule | any = new Users();

    public dataGPS: any = {
        coords:{
            latitude: 0,
            longitude: 0
        }
    };

    constructor() { }

    ngOnInit() {
        this.clock();
    }

    public onSubmit ($event) {

        //get data users
        this.isGPSLoaded = false;
        this.timestampContentStatus = '<i class="material-icons">room</i> กำลังดึงข้อมูลตำแหน่ง...';
        if (!navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {

                this.isGPSLoaded = true;
                
                this.dataGPS = position;

                this.onTimestamp();
            });
        } else {
            this.isGPSLoaded = true;
            this.dataGPS.coords.latitude = 0;
            this.dataGPS.coords.longitude = 0;
            this.onTimestamp();
        }

    }

    public onTimestamp() {

        let formHelper = new FormHelperModule();

        //get data users
        this.timestampContentStatus = '<i class="material-icons">perm_contact_calendar</i> กำลังดึงข้อมูลผู้ใช้งาน...';
        this.isUsersLoaded = false;
        const usersCode = this.form.users_code.replace(/\s+/g, '').toLowerCase().replace("o", "0");
        UsersService.getUsersById({users_code: usersCode}).subscribe(
            (data: any) => {

                if ( !data.users ) {
                    formHelper.notify('danger', 'ไม่พบข้อมูลผู้ใช้งานในระบบ');
                    this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                    return;
                }
                
                if ( data.users.utype_id != 3 ) {
                    formHelper.notify('danger', 'หน้าต่างลงเวลานี้ไม่อนุญาตให้ ' + data.users.utype_name + ' ทำรายการได้');
                    this.timestampContentStatus = '<i class="material-icons">highlight_off</i> ' + 'หน้าต่างลงเวลานี้ไม่อนุญาตให้ ' + data.users.utype_name + ' ทำรายการได้';
                    return;
                }

                if ( !data.users.department_id ) {
                    formHelper.notify('danger', 'ข้อมูลบัญชีผู้ใช้งานยังไม่ถูกผูกกับรหัสหน่วยงาน');
                    this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                    return;
                }

                this.dataUsers = data.users;

                //get data branch
                this.timestampContentStatus = '<i class="material-icons">location_city</i> กำลังดึงข้อมูลหน่วยงาน...';
                this.isDepartmentLoaded = false;
                BranchService.getDepartmentById({department_id: this.dataUsers.department_id}).subscribe(
                    (data: any) => {

                        if ( !data.department ) {
                            formHelper.notify('danger', 'ไม่พบข้อมูลสาขา');
                            this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                            return;
                        }

                        this.dataDepartment = data.department;

                        let schedule = {
                            btype_before_clockin: null,
                            btype_clockin: null,
                            btype_after_clockin: null,
                            btype_clockout: null,
                        };

                        //driver
                        schedule.btype_before_clockin = this.dataDepartment.btype_driver_before_clockin;
                        schedule.btype_clockin = this.dataDepartment.btype_driver_clockin;
                        schedule.btype_after_clockin = this.dataDepartment.btype_driver_after_clockin;
                        schedule.btype_clockout = this.dataDepartment.btype_driver_clockout;

                        let dataPost = {
                            latitude: this.dataGPS.coords.latitude,
                            longitude: this.dataGPS.coords.longitude,
                            users_id: this.dataUsers.users_id,
                            users_branch_id: this.dataUsers.branch_id,
                            utype_id: this.dataUsers.utype_id,
                            branch_id: this.dataDepartment.branch_id,
                            department_id: this.dataDepartment.department_id,
                            branch_code: ((this.dataUsers.utype_id == 4 || this.dataUsers.utype_id == 6) ? this.dataDepartment.branch_code : (this.dataUsers.branch_id == this.dataDepartment.branch_id ? this.dataUsers.users_coderef : this.dataDepartment.branch_code)),
                            btype_id: this.dataDepartment.btype_id,
                            btype_schedule: this.dataDepartment.btype_schedule,
                            users_code: this.dataUsers.users_code,
                            btype_before_clockin: schedule.btype_before_clockin,
                            btype_clockin: schedule.btype_clockin,
                            btype_after_clockin: schedule.btype_after_clockin,
                            btype_clockout: schedule.btype_clockout,
                        };
                        this.isTransactionLoaded = false;
                        this.timestampContentStatus = '<i class="material-icons">swap_horiz</i> กำลังทำรายการ...';
                        TimestampService.insertTimestamp(dataPost).subscribe(
                            (data: any) => {

                                if ( data.error ) {
                                    formHelper.notify('danger', data.error);
                                    this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                                    return;
                                }

                                //get summary timestamp
                                this.timestampSummaryComponent.summary.timestampResponse = data.timestamp;
                                this.timestampSummaryComponent.summary.timestampStatus = data.timestamp_status;
                                this.timestampSummaryComponent.summary.timestampType = data.timestamp_type;
                                this.timestampSummaryComponent.summary.timestampCheckinType = data.timestamp_checkin_type;
                                this.timestampSummaryComponent.summary.timestampRound = data.timestamp_round;
                                this.timestampSummaryComponent.summary.dataUsers = this.dataUsers;
                                this.timestampSummaryComponent.summary.dataBranch = this.dataDepartment;

                                //evt
                                this.ngForm.resetForm();
                                formHelper.notify('success', 'ลงเวลาเข้าระบบสำเร็จ');
                                this.timestampContentStatus = '<i class="material-icons">check_circle_outline</i> พร้อมใช้งาน';
                                $('#summaryTimestampModal').modal('show');


                            }, () => { this.isTransactionLoaded = true; this.timestampContentStatus = '<i class="material-icons">highlight_off</i> พบปัญหาระหว่างการทำรายการ'; },
                            () => { this.isTransactionLoaded = true; }
                        );

                    }, () => { this.isDepartmentLoaded = true; this.timestampContentStatus = '<i class="material-icons">highlight_off</i> พบปัญหาการดึงข้อมูลสาขา'; },
                    () => { this.isDepartmentLoaded = true; }
                );
            }, () => { this.isUsersLoaded = true; this.timestampContentStatus = '<i class="material-icons">highlight_off</i> พบปัญหาการดึงข้อมูลผู้ใช้งาน'; },
            () => { this.isUsersLoaded = true; }
        );
    }
    
    public clock() {
        this.currentDate = ToolHelperModule.getCurrentDate(null);
        setTimeout(callback => {
            this.clock();
        }, 500);
    }

}
