import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule
} from "@angular/material";
import {RouterModule} from "@angular/router";
import {TimestampDriverComponent} from "./timestamp-driver.component";
import {TimestampSummaryModule} from "../../timestamp/timestamp-summary/timestamp-summary.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatFormFieldModule, MatInputModule, MatButtonModule, MatSelectModule,
        MatIconModule,
        MatTooltipModule,
        TimestampSummaryModule
    ],
    declarations: [
        TimestampDriverComponent,
    ],
})
export class TimestampDriverModule { }
