<div class="container-fluid">

    <div class="row" style="margin-top: 15px">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-success">
                    <h4 class="card-title ">หน้าลงเวลาเข้างานระบบ (ลงเวลา,ลงเวลาแทน)</h4>
                    <p class="card-category">{{currentDate}}</p>
                </div>
                <div class="card-body">
                    <form (ngSubmit)="form.valid && onSubmit($event)" [formGroup]="form">
                        <div class="row align-items-center">
                            <div class="col">

                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4" style="border: 1px dashed gainsboro; text-align: center">
                                <div class="row">
                                    <div class="col-md-12">
                                        <a href="javascript:void(0)" (click)="checkGPS();" matTooltip="Refresh location" style="float: right;"><i class="fa fa-refresh" [class.fa-spin]="!isBranchLoaded || !isTransactionLoaded || !isUsersLoaded || !isNearBranchLoaded"></i></a>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <mat-form-field class="full-width">
                                                <label style="font-size: 16px">เลือกสาขา (ค้นพบ {{dataNearBranch.length}})<span class="text-danger">*</span></label>
                                                <mat-select formControlName="branch_id" [compareWith]="compareObjects" #branchSelect required>
                                                    <mat-option *ngFor="let item of dataNearBranch" [value]="item.branch_id">{{item.branch_name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <mat-form-field class="full-width">
                                                <label style="font-size: 16px">กรอกรหัสพนักงาน <span class="text-danger">*</span></label>
                                                <input matInput type="text" formControlName="users_code" required>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <p [innerHTML]="timestampContentStatus"></p>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <button type="submit" mat-raised-button class="btn btn-primary btn-lg btn-block" [disabled]="!isBranchLoaded || !isTransactionLoaded || !isUsersLoaded || !isNearBranchLoaded" *ngIf="isBranchLoaded || isTransactionLoaded || isUsersLoaded || isNearBranchLoaded"><span *ngIf="isBranchLoaded && isTransactionLoaded && isUsersLoaded && isNearBranchLoaded">ลงเวลา</span><span *ngIf="!isBranchLoaded || !isTransactionLoaded || !isUsersLoaded || !isNearBranchLoaded"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Connecting...</span></button>
                                            <!--<button type="button" mat-raised-button class="btn btn-default btn-lg btn-block" data-target="#holidayModal" data-toggle="modal">วันหยุดประเพณี 2562</button>-->
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col">

                            </div>
                        </div>
                    </form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group text-left">
                                <br>
                                <a [href]="slipUrl">สลิปเงินเดือน</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-timestamp-summary></app-timestamp-summary>
<guess-timestamp-holiday></guess-timestamp-holiday>
