import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import {PageNotFoundComponent} from "./components/base/page-not-found/page-not-found.component";

const routes: Routes =[
    { path: '', redirectTo: '/guess/timestamp', pathMatch: 'full' },
    { path: 'page-message', component: PageNotFoundComponent, pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent, pathMatch: 'full' },
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
