import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {FooterComponent} from "./footer/footer.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {PageMessageComponent} from "./page-message/page-message.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
    declarations: [
        FooterComponent,
        PageNotFoundComponent,
        PageMessageComponent,
    ],
    exports: [
        FooterComponent,
    ]
})
export class BaseModule { }
