import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TimestampModule} from "./timestamp/timestamp.module";
import {TimestampDriverModule} from "./timestamp-driver/timestamp-driver.module";
import {GuessRoutingModule} from "./guess-routing.module";

@NgModule({
    imports: [
        CommonModule,
        GuessRoutingModule,
        TimestampModule,
        TimestampDriverModule
    ],
    exports: [
    ],
    declarations: [],
})
export class GuessModule { }
