import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TimestampComponent} from "app/components/guess/timestamp/timestamp.component";
import {TimestampDriverComponent} from "./timestamp-driver/timestamp-driver.component";

const routes: Routes = [
    { path: 'guess/timestamp', component: TimestampComponent, pathMatch: 'full' },
    { path: 'guess/timestamp-driver', component: TimestampDriverComponent, pathMatch: 'full' },
];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
})
export class GuessRoutingModule { }
