import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';

import {GuessRoutingModule} from "app/components/guess/guess-routing.module";

import * as bootstrap from "bootstrap";
import * as $ from "jquery";
import {ErrorStateMatcher, MAT_SNACK_BAR_DEFAULT_OPTIONS, ShowOnDirtyErrorStateMatcher} from "@angular/material";
import {BaseModule} from "./components/base/base.module";
import {GuessModule} from "./components/guess/guess.module";

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        GuessModule,
        RouterModule,
        BaseModule,
        AppRoutingModule,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
        {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
        ],
    bootstrap: [AppComponent]
})
export class AppModule { }
