import { Injectable } from '@angular/core';
import {HttpClient, HttpXhrBackend, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

    //attr
    public static http = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    public static httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    
    constructor() { }

    //users
    public static getUsers(data : object) {
        return this.http.post(environment.getBaseUrl('users_service/get_users'), data);
    }

    public static getUsersById(data : object) {
        return this.http.post(environment.getBaseUrl('users_service/get_users_by_id'), data);
    }

    public static insertUsers(data : object) {
        return this.http.post(environment.getBaseUrl('users_service/insert_users'), data, this.httpOptions);
    }

    public static editUsers(data : object) {
        return this.http.post(environment.getBaseUrl('users_service/edit_users'), data, this.httpOptions);
    }

    public static deleteUsers(data : object) {
        return this.http.post(environment.getBaseUrl('users_service/delete_users'), data, this.httpOptions);
    }

    //users
    public static getUsersType(data : object) {
        return this.http.post(environment.getBaseUrl('users_service/get_users_type'), data);
    }

    public static getUsersTypeById(data : object) {
        return this.http.post(environment.getBaseUrl('users_service/get_users_type_by_id'), data);
    }

}
