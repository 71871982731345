
<!-- The Modal -->
<div class="modal" id="summaryTimestampModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"><i class="material-icons">check_circle</i>&nbsp;บันทึกสำเร็จ!. สรุปรายการลงเวลา</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table">
                            <tbody>
                            <tr><td><small>วันที่และเวลาที่บันทึก (วัน/เดือน/ปี ชั่วโมง/นาที/วินาที):</small>&nbsp;<b>{{summary.timestampResponse}}</b></td></tr>
                            <tr><td><small>สถานะ (ปกติ/สาย):</small>&nbsp;<b>{{summary.timestampStatus == 0 ? 'ปกติ' : 'สาย'}}</b></td></tr>
                            <tr><td><small>ประเภทการลงเวลา (เข้า/ออก):</small>&nbsp;<b>{{summary.timestampCheckinType == 1 ? 'เข้า' : 'ออก'}}</b></td></tr>
                            <tr><td><small>รหัสพนักงาน:</small>&nbsp;<b>{{summary.dataUsers.users_code}}</b></td></tr>
                            <tr><td><small>ชื่อพนักงาน:</small>&nbsp;<b>{{summary.dataUsers.users_title}} {{summary.dataUsers.users_name}} {{summary.dataUsers.users_surname}}</b></td></tr>
                            <tr><td><small>รหัสสาขาและชื่อสาขา:</small>&nbsp;<b>{{summary.dataBranch.branch_code}},&nbsp;{{summary.dataBranch.branch_name}}</b></td></tr>
                            <tr *ngIf="summary.dataUsers.utype_id == 3"><td><small>รหัสหน่วยงานและชื่อหน่วยงาน:</small>&nbsp;<b>{{summary.dataBranch.department_code}},&nbsp;{{summary.dataBranch.department_name}}</b></td></tr>
                            <tr><td><small>ประเภทลงเวลาพนักงาน:</small>&nbsp;<b>{{summary.timestampType == 0 ? 'ประจำสาขา' : (summary.timestampType == 1 ? 'ลงเวลาแทน' : 'ชั่วคราว')}}</b></td></tr>
                            <tr *ngIf="summary.dataUsers.utype_id == 4 || summary.dataUsers.utype_id == 6"><td><small>วงรอบที่:</small>&nbsp;<b>{{summary.timestampRound}}</b></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">ปิด</button>
            </div>

        </div>
    </div>
</div>